import React from 'react'
import SbEditable from 'storyblok-react'
import { makeStyles } from '@material-ui/styles'
import { Box } from '@material-ui/core'
import renderBloks from '@renderBloks'
import { H3, ContentContainer, PageContainer } from '@system'

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '4rem 2rem',
    backgroundColor: 'white',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column-reverse',
      padding: '3rem 1.5rem',
    },
  },
  column1: {
    width: '60%',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  column2: {
    width: '30%',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
}))

const Feature = (props) => {
  const classes = useStyles()
  const { title, cta, image, shouldRenderH1 } = props.blok

  return (
    <SbEditable content={props.blok}>
      <PageContainer>
        <ContentContainer>
          <Box className={classes.container}>
            <Box className={classes.column1}>
              <H3 component={shouldRenderH1 ? 'h1' : 'h3'}>{title}</H3>
              {renderBloks(cta)}
            </Box>
            <Box className={classes.column2}>{renderBloks(image)}</Box>
          </Box>
        </ContentContainer>
      </PageContainer>
    </SbEditable>
  )
}

export default Feature
